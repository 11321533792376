import React, { useState, useEffect } from 'react';
import { BudgetContainer, VersionsList, HeaderSection } from './style'
import CustomButton from '../CustomButton/CustomButton';
import BudgetVersionAccordion from '../BudgetVersionAccordion/BudgetVersionAccordion';
// import SuccessSnackBar from '../SuccessSnackBar/SuccessSnackBar';
import { CircularProgress, Backdrop } from '@mui/material';
import api from '../../api/api';

const BudgetBody = ({ budget }) => {
    // const [open, setOpen] = React.useState(true);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [versions, setVersions] = useState([]);

    const handleGetVersions = () => {
        var getVersionsByBudgetBase = '/Budget/GetBudgetVersionsByBudget'
        var formData = { baseBudgetId: budget.baseBudgetId }

        api.post(getVersionsByBudgetBase, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setVersions(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleCreateNewVersion = () => {
        var createNewURL = '/Budget/CreateNewBudgetVersion'
        var formData = { baseBudgetId: budget.baseBudgetId }

        api.post(createNewURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Nova versão de budget criada com sucesso!") {
                handleGetVersions();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (budget) {
            handleGetVersions();
        }
    }, [budget])

    return (
        <BudgetContainer variant='outlined'>
            <HeaderSection>
                <div className='title-container'><p p='title-small'>Versões</p></div>
                <CustomButton variant="contained" onClick={handleCreateNewVersion}>
                    <span className='title-small'>Nova versão +</span>
                </CustomButton>
            </HeaderSection>

            <VersionsList>
                {versions.map((version, i) => (
                    <BudgetVersionAccordion
                        key={i}
                        version={version}
                        openBackdrop={openBackdrop}
                        setOpenBackdrop={setOpenBackdrop}
                        job={budget} />
                ))}
            </VersionsList>

            {openBackdrop &&
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={openBackdrop}
                    onClick={() => { setOpenBackdrop(false) }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>}

            {/* <SuccessSnackBar open={open} setOpen={setOpen} /> */}
        </BudgetContainer>
    );
};

export default BudgetBody;
