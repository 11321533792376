import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import TextFieldComp from '../../TextFieldComp/TextFieldComp'
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "./style"
import { ErrorFeedback } from "../../ErrorFeedback/ErrorFeedback"
import { SuccessFeedback } from "../../SuccessFeedback/SuccessFeedback"
import Backdrop from "../../Backdrop/Backdrop";
import { useCompany } from "../../../hooks/useCompany";

const EditCompanyModal = ({ open, handleClose, companyData }) => {
    const [companyName, setCompanyName] = useState(companyData.companyName);
    const { handleEditCompanyName, isLoading, error, requestSuccess } = useCompany();

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {!requestSuccess && !error ? <>
                <ModalTitle className="title-large">
                    Editar Empresa
                </ModalTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "#000"
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <ModalContent>
                    <TextFieldComp placeholder={"ex:. Google"} value={companyName} setValue={setCompanyName} label='Nome da empresa' fullWidth={true} type='text' />
                </ModalContent>

                <ModalActions>
                    <CustomButton sx={{ minWidth: '50%' }} onClick={() => { handleEditCompanyName(companyData.id, companyName, handleClose) }} variant={"contained"}>
                        <span className="title-small">
                            Salvar
                        </span>
                    </CustomButton>
                </ModalActions></> : null}

            <Backdrop open={isLoading} />

            {requestSuccess && <SuccessFeedback feedbackText={"Nome da empresa atualizado com sucesso."} />}
            {error && <ErrorFeedback feedbackText={error} />}

        </DialogModal>
    )
}

export default EditCompanyModal;