import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "../TermModal/style"
import BudgetCalculator from "../../BudgetCalculator/BudgetCalculator";
import api from "../../../api/api";

const EditBudgetSummary = ({ open, handleClose, summary, budgetVersionId, format }) => {
    const [updatedPayload, setUpdatedPayload] = useState(0)

    const handleSave = () => {
        var updateBudgetURL = "/Budget/SubmitUpdatedBudget";
        console.log(updatedPayload)
        api.post(updateBudgetURL, updatedPayload).then((response) => {
            return response.data;
        }).then((data) => {
            if (data.token) {
                handleAddToBudget(data.token);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleAddToBudget = (token) => {
        var updateFormatPersonalizationURL = "/FormatPersonalization/UpdateBudgetVersionFormatPersonalizations";

        var formData = {
            token: token,
            budgetId: budgetVersionId
        }

        api.post(updateFormatPersonalizationURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Atualização de budget realizada com sucesso!") {
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ minWidth: '450px', gap: '10px' }}
        >
            <ModalTitle className="title-large">
                Editar cotação
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent>
                <BudgetCalculator summary={summary} setUpdatedPayload={setUpdatedPayload} />
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleSave} variant={"contained"}>
                    <span className="title-small">
                        Salvar
                    </span>
                </CustomButton>
            </ModalActions>
        </DialogModal>
    )
}

export default EditBudgetSummary;