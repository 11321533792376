import React from "react";
import { Dialog, DialogContent, } from "@mui/material";
import { SuccessFeedback } from "../../SuccessFeedback/SuccessFeedback";

const SendVersionFeedback = ({ open, handleClose }) => {
    
    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', width: '35%', minWidth: '200px', textAlign: 'center' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SuccessFeedback feedbackText={"Versão enviada!"} />

            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <p className="body-large">
                    O budget será enviado para o endereço de e-mail do cliente.
                </p>
            </DialogContent>
        </Dialog>
    )
}

export default SendVersionFeedback;