import React, { useState } from "react";
import { Dialog, Fab, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import api from "../../../api/api";
import Backdrop from "../../Backdrop/Backdrop";
import { useNavigate } from "react-router-dom";
import { SuccessFeedback } from "../../SuccessFeedback/SuccessFeedback";

const DeleteBudgetAlertModal = ({ open, budgetId, budgetName, handleClose }) => {
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [requestSuccess, setRequestSuccess] = useState(false)
    const navigate = useNavigate()

    const handleDelete = () => {
        var deleteURL = '/Job/DeleteJob/' + budgetId;
        setOpenBackdrop(true);
        api.delete(deleteURL)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                if (data === "Job apagado com sucesso.") {
                    setOpenBackdrop(false);
                    setRequestSuccess(true);
                    setTimeout(() => {
                        navigate('/budgets');
                    }, 1500);
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', width: '35%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {!requestSuccess && <>
                <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                    <Fab color="warning" disableFocusRipple disableRipple disableTouchRipple size="small"
                        sx={{ boxShadow: 'none', cursor: 'initial', color: "#fff", ":active": { boxShadow: "none" }, display: 'block' }}>
                        <ReportProblemOutlinedIcon />
                    </Fab>
                    Atenção!
                </DialogTitle>

                <DialogContent sx={{ padding: 0 }}>
                    <p className="body-large">
                        Ao excluir o  <span className="bold">budget Nº{budgetName} </span> ele não existirá mais no banco de dados.<span className="bold"> Essa ação é irreversível.</span>
                    </p>
                </DialogContent>

                <DialogActions sx={{ padding: 0 }}>
                    <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                        <span className="title-small">Cancelar</span>
                    </CustomButton>
                    <CustomButton onClick={handleDelete} variant={"contained"} fullWidth>
                        <span className="title-small">
                            Excluir
                        </span>
                    </CustomButton>
                </DialogActions></>}
            {requestSuccess && <SuccessFeedback feedbackText={"Budget excluído com sucesso!"} />}
            <Backdrop open={openBackdrop} />
        </Dialog>
    )
}

export default DeleteBudgetAlertModal;