import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import TextFieldComp from '../../TextFieldComp/TextFieldComp'
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "./style"
import api from "../../../api/api";

const CreateCompanyModal = ({ open, handleClose }) => {
    const [companyName, setCompanyName] = useState("");

    const handleCreate = () => {
        var createCompany = '/Company/Create'
        var formData = { name: companyName }

        api.post(createCompany, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data.message === "Company created successfully!") {
                window.location.reload();
                handleClose();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalTitle className="title-large">
                Nova Empresa
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent>
                <TextFieldComp placeholder={"ex:. Google"} value={companyName} setValue={setCompanyName} label='Nome da empresa' fullWidth={true} type='text' />
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleCreate} variant={"contained"}>
                    <span className="title-small">
                        Salvar
                    </span>
                </CustomButton>
            </ModalActions>
        </DialogModal>
    )
}

export default CreateCompanyModal;