import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, ProfilePaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"

const ProfileForm = () => {
    const [authForm, setAuthForm] = useState({
        name: '',
        email: '',
    });

    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }
    const handleAuthentication = (e) => {
        // setAuthForm(authForm => ({
        //     ...authForm,
        //     [e.target.name]: e.target.value
        // }));
    }

    return (
        <ProfilePaper elevation={2} >
            <p className="headline-small">Meu Perfil</p>
            <AuthForm onSubmit={handleAuthentication}>
                <TextFieldComp
                    name={"name"}
                    label={"Nome"}
                    value={authForm.name}
                    onChange={handleCredentials}
                    type={"text"}
                    fullWidth={true}
                    placeholder="user@gmail.com"
                    required
                />
                <TextFieldComp
                    name={"email"}
                    label={"E-mail"}
                    value={authForm.email}
                    onChange={handleCredentials}
                    type={"email"}
                    fullWidth={true}
                    placeholder="Digite seu e-mail aqui"
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>
        </ProfilePaper>
    )
}

export default ProfileForm;