import React from "react"
import { Fab, DialogTitle } from "@mui/material"
import { TaskAlt } from "@mui/icons-material"

export const SuccessFeedback = ({ feedbackText }) => {


    return (
        <DialogTitle sx={{ padding: '20px 0', gap: '10px', display: 'flex', textAlign: 'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
            <Fab color="success" disableFocusRipple disableRipple disableTouchRipple size="small"
                sx={{
                    boxShadow: 'none', cursor: 'initial', color: "#fff",
                    ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                <TaskAlt />
            </Fab>
            {feedbackText}
        </DialogTitle>)
}