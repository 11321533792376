import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { editCompanyName } from "../api/companyApi";

export const useCompany = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [requestSuccess, setRequestSuccess] = useState(false);
    const navigate = useNavigate();

    const handleEditCompanyName = async (companyId, companyName, handleClose) => {
        setIsLoading(true);
        setError(null);

        var data = { id: companyId, companyName: companyName }
        try {
            const response = await editCompanyName(data);

            if (response === "Nome da empresa atualizado com sucesso.") {
                setRequestSuccess(true);
                setTimeout(() => {
                    handleClose();
                    setRequestSuccess(false);
                    navigate("/empresas/" + encodeURI(companyName));
                }, 1500);
            }
        } catch (err) {
            setError("Erro ao atualizar nome da empresa.");
        } finally {
            setIsLoading(false);
        }
    };

    return { handleEditCompanyName, isLoading, error, requestSuccess };
};