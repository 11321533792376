import React, { useState, useEffect, useContext } from "react";
import { Add } from "@mui/icons-material"
import { Container, PageHeader } from "./style";
import CompaniesTable from "../../components/CompaniesTable/CompaniesTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import CreateCompanyModal from "../../components/Modal/CreateCompanyModal/CreateCompanyModal";
import { CompanyContext } from '../../contexts/CompanyContext';
import { CircularProgress } from "@mui/material";
import { checkToken } from "../../utils/methods";

const CompanyListPage = () => {
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const { companyList } = useContext(CompanyContext)
    const [filteredCompanies, setFilteredCompanies] = useState([...companyList]);
    const [requestStatus, setRequestStatus] = useState("INITIAL")

    const handleOpenCreateCompany = () => {
        setOpenCreateModal(true)
    }

    useEffect(() => {
        if(companyList.length > 0){
            setRequestStatus("LOADED")
        }else{
            setRequestStatus("NOT FOUND")
        }
        setFilteredCompanies([...companyList])
    }, [companyList])

    const filterCompanyList = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredList = companyList.filter((item) =>
            item.name.toLowerCase().includes(searchTerm))

        if (e.target.value === '' || e.target.value === undefined) {
            setFilteredCompanies(companyList);
        } else {
            setFilteredCompanies(filteredList)
        }
    }

    useEffect(() => {
        checkToken();
    }, [])

    return (
        <Container>
            <PageHeader>
                <div className="headline-small">Empresas {" "}
                    <div onClick={handleOpenCreateCompany} className="add-button">
                        <Add sx={{ color: '#fff' }} />
                    </div>
                </div>
                <SearchBar onChange={filterCompanyList} placeholder={"Pesquise uma empresa..."} />
            </PageHeader>

            {requestStatus === "LOADED" && <CompaniesTable companies={filteredCompanies} />}
            {requestStatus === "NOT FOUND" && <p className="title-medium">Ah, ainda não existem empresas.</p>}
            {requestStatus === "INITIAL" && <div className="loading-container">   
                <CircularProgress color="inherit" />
                <p className="title-medium">Carregando...</p>
            </div>}

            <CreateCompanyModal open={openCreateModal} handleClose={() => { setOpenCreateModal(false) }} />
        </Container>
    )
}

export default CompanyListPage;