import React, { useEffect, useState } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import JobHeader from "../../components/JobHeader/JobHeader";
import { Link, useParams, useNavigate } from "react-router-dom"
import JobBody from "../../components/JobBody/JobBody";
import DeleteBudgetAlertModal from "../../components/Modal/DeleteBudgetAlertModal/DeleteBudgetAlertModal";
import { Breadcrumbs, Skeleton } from "@mui/material";
import api from "../../api/api";
import { StatusProvider } from "../../contexts/StatusContext";
import { checkToken } from "../../utils/methods";
import CustomButton from "../../components/CustomButton/CustomButton";
import axios from "axios";
import SendNotificationModal from "../../components/Modal/SendNotificationModal/SendNotificationModal";

const JobPage = () => {
    const [openModalDeleteBudget, setOpenModalDeleteBudget] = useState(false);
    const [openModalNotification, setOpenModalNotification] = useState(false);
    const [jobData, setJobData] = useState(null);
    const [isCapeDataAvailable, setIsCapeDataAvailable] = useState(false);
    const { companyName, jobId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();
        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": jobId
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJobData(data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        if (jobData) {
            checkCapeData();
        }
    }, [jobData])

    const handleOpenPreview = () => {
        navigate("preview")
    }

    const checkCapeData = () => {
        axios.get("https://storage.bycape.io/account-221/fixed/DownloadsPreviews_Feed.json")
            .then(response => response.data)
            .then(data => {
                const campaign = data.filter((c) => c.replicaPortalId === jobId)
                if (campaign.length > 0) {
                    if (jobData.status === "Replicating" || jobData.status === "Delivered") {
                        setIsCapeDataAvailable(true)
                    }
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    return (
        <StatusProvider>
            {jobData ?
                <Container>
                    <PageHeader>
                        {(jobData?.status !== "Replicating" && jobData?.status !== "Delivered") &&
                            <Breadcrumbs>
                                <Link to={'/empresas/' + encodeURI(companyName)}>
                                    {companyName}
                                </Link>
                            </Breadcrumbs>}
                        <Link to={"/empresas/" + encodeURI(companyName)} className="title-small">
                            <ArrowBack /> Voltar
                        </Link>
                        <div className="btns-preview">
                            <CustomButton variant="outlined" onClick={() => { setOpenModalNotification(true) }}>
                                <span className='title-small'>Enviar notificação</span>
                            </CustomButton>
                            {isCapeDataAvailable &&
                                <CustomButton variant="contained" onClick={handleOpenPreview}>
                                    <span className='title-small'> Acompanhe a produção</span>
                                </CustomButton>}
                        </div>
                    </PageHeader>

                    <JobHeader job={jobData} />
                    <JobBody job={jobData} />

                    <DeleteBudgetAlertModal
                        open={openModalDeleteBudget}
                        handleClose={() => { setOpenModalDeleteBudget(false) }}
                    />
                </Container>
                : <Container>
                    <PageHeader>
                        <Breadcrumbs>
                            <Link to={'/empresas/' + companyName}>
                                {companyName}
                            </Link>
                        </Breadcrumbs>
                        <Link to={"/empresas/"} className="title-small">
                            <ArrowBack /> Voltar
                        </Link>
                    </PageHeader>
                    <Skeleton variant="rounded" width={"100%"} height={"10%"} />
                    <Skeleton variant="rounded" width={"100%"} height={"15%"} />
                </Container>}

            {openModalNotification &&
                <SendNotificationModal
                    job={jobData}
                    open={openModalNotification}
                    handleClose={() => { setOpenModalNotification(false) }}
                />
            }
        </StatusProvider>
    )
}

export default JobPage;