import React, { useEffect, useState } from "react";
import { IconButton, Switch, Tooltip } from '@mui/material';
import { FileDownloadOutlined, DeleteOutlined } from '@mui/icons-material';
import { VersionRow, Summary, VersionInfo, ActionButtons, Details } from './style'
import BudgetTerms from "../BudgetTerms/BudgetTerms";
import QuotationSummary from "../QuotationSummary/QuotationSummary";
import CustomButton from "../CustomButton/CustomButton"
import DeleteVersionModal from "../Modal/DeleteVersionModal/DeleteVersionModal";
import SendVersionFeedback from "../Modal/SendVersionFeedback/SendVersionFeedback";
import api from "../../api/api"

const BudgetVersionAccordion = ({ job, version, setOpenBackdrop, openBackdrop }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [termsOrdered, setTermsOrdered] = useState([]);

    useEffect(() => {
        if (version) {
            const orderedList = version.terms.sort((a, b) => a.position - b.position)
            setTermsOrdered(orderedList)
        }
    }, [version])

    const handleOpenDeleteModal = (e) => {
        e.preventDefault()
        setOpenDeleteModal(true);
    }
    const handleSendVersionEmail = (e) => {
        setOpenBackdrop(true);
        var sendVersionURL = '/Budget/SendBudgetVersionEmail/';
        var formData = {
            "jobId": job.jobId,
            "budgetVersionId": version.budgetVersionId,
            "clientEmail": job.clientEmail,
            "clientName": job.clientName
        }

        api.post(sendVersionURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Email enviado com sucesso!") {
                setOpenBackdrop(false);
                setOpenFeedback(true);
            }
        }).catch((error) => {
        })
    }

    const handleSwitchCurrentBudget = (e) => {
        e.preventDefault()
        var setActiveURL = '/Budget/SetActiveBudget/';
        var formData = {
            "jobId": job.jobId,
            "budgeVersionId": version.budgetVersionId,
        }

        api.post(setActiveURL, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Alteração realizada com sucesso!") {
                window.location.reload();
            }
        }).catch((error) => {
        })
    }

    const handleDownloadVersion = () => {
        setOpenBackdrop(true);
        var versionPDFURL = '/Budget/DownloadBudgetVersionPdf';
        var formData = {
            jobId: job.jobId,
            budgetId: version.budgetVersionId
        }

        api.post(versionPDFURL, formData, { responseType: 'blob' })
            .then((response) => {
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `${job.companyName.replace(" ", "")}-orcamento-V${version.version}.pdf`;

                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(fileURL);
                link.remove();
                setOpenBackdrop(false);
            }).catch((error) => {
                console.error(error)
            })
    }

    return (
        <VersionRow variant="outlined" expanded={isExpanded}>
            <Summary
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <VersionInfo onClick={() => { setIsExpanded(!isExpanded) }}>
                    <p className='title-small'>Versão {version.version}</p>|
                    <p className='title-small'>{version.createdAt}</p>
                </VersionInfo>

                <ActionButtons>
                    {/* <Tooltip arrow title={'Duplicar versão'}>
                        <IconButton aria-label="copy" onClick={}>
                            <ContentCopyOutlined />
                        </IconButton>
                    </Tooltip> */}
                    {version.version > 1 &&
                        <Tooltip arrow title={'Deletar versão'}>
                            <IconButton aria-label="delete" onClick={handleOpenDeleteModal}>
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>}
                    <Tooltip arrow title={'Baixar versão'}>
                        <IconButton aria-label="download" onClick={handleDownloadVersion}>
                            <FileDownloadOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title={'Ativar versão'}>
                        <Switch checked={version.isActive} color="success" onChange={handleSwitchCurrentBudget} />
                    </Tooltip>
                </ActionButtons>
            </Summary>
            <Details>
                <QuotationSummary summary={version.budgetResume} due={version.due} budgetVersionId={version.budgetVersionId} />
                <BudgetTerms terms={termsOrdered} budgetVersionId={version.budgetVersionId} />
                <CustomButton onClick={handleSendVersionEmail} variant={"contained"} sx={{ alignSelf: "end" }}>
                    <span className="title-small">Enviar versão por e-mail</span>
                </CustomButton>
            </Details>

            {openFeedback && <SendVersionFeedback open={openFeedback} handleClose={() => { setOpenFeedback(false) }} />}
            {openDeleteModal && <DeleteVersionModal version={version.version} budgetVersionId={version.budgetVersionId} open={openDeleteModal} handleClose={() => { setOpenDeleteModal(false) }} />}
        </VersionRow>
    )
}

export default BudgetVersionAccordion