import React, { useContext, useState } from "react";
import { Dialog, Fab, DialogTitle, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import api from "../../../api/api";
import { CompanyContext } from "../../../contexts/CompanyContext";
import Backdrop from "../../Backdrop/Backdrop"

const AddUserToCompanyAlert = ({ open, handleClose, companyId, userEmail }) => {
    const { getNameFromId } = useContext(CompanyContext)
    const [loading, setLoading ] = useState(false)

    const handleAddUserToCompany = () => {
        var allCompaniesURL = `/Company/AddUserToCompany`
        var formData = {
            companyId: companyId,
            userEmail: userEmail
        }

        setLoading(true)
        api.post(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Usuário vinculado a uma empresa com sucesso.') {
                    setLoading(false)
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '300px', width: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                <Fab color="warning" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{ boxShadow: 'none', cursor: 'initial', color: "#fff", ":active": { boxShadow: "none" }, display: 'block' }}>
                    <ReportProblemOutlinedIcon />
                </Fab>
                Atenção!
            </DialogTitle>

            <DialogContent sx={{ padding: 0 }}>
                <p className="body-large">
                    Ao adicionar o usuário <span className="bold">{userEmail} </span>
                    á uma empresa, todos os budgets desse usuário, serão atribuídos a<span className="bold"> {getNameFromId(companyId)}.</span>  Essa ação é irreversível.
                </p>
            </DialogContent>

            <DialogActions sx={{ padding: 0 }}>
                <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                    <span className="title-small">Cancelar</span>
                </CustomButton>
                <CustomButton onClick={handleAddUserToCompany} variant={"contained"} fullWidth>
                    <span className="title-small">
                        Adicionar
                    </span>
                </CustomButton>
            </DialogActions>
            {loading && <Backdrop open={loading} />}
        </Dialog>
    )
}

export default AddUserToCompanyAlert;