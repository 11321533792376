import React from "react"
import { Fab, DialogTitle } from "@mui/material"
import { ErrorOutline } from "@mui/icons-material"
import CustomButton from "../CustomButton/CustomButton"

export const ErrorFeedback = ({ feedbackText }) => {
    return (
        <DialogTitle sx={{ padding: '20px 0', gap: '10px', display: 'flex', textAlign: 'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
            <Fab color="error" disableFocusRipple disableRipple disableTouchRipple size="small"
                sx={{
                    boxShadow: 'none', cursor: 'initial', color: "#fff",
                    ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                <ErrorOutline />
            </Fab>
            {feedbackText}
            <CustomButton variant={'contained'} onClick={() => { window.location.reload() }}>
                <span className="title-small">Recarregar página</span>
            </CustomButton>
        </DialogTitle>)
}